body {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #246524, #0e520e);
  color: linear-gradient(180deg, #246524, #0e520e);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-style: bold;
}
